import { useEffect, useState } from "react";
import ContentAreaWrapper from "../components/base/ContentAreaWrapper";
import { Pagination, Typography } from "@mui/material";
import useCompanies from "../hooks/useCompanies";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../services/firebase";
import { LoadingOverlay } from "../components/commons/LoadingOverlay";

const CompaniesScreen = () => {
  const [user] = useAuthState(auth);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 20;

  const { companyPage, getCompanies, loadingCompanies, companiesError } =
    useCompanies();

  useEffect(() => {
    if (user) {
      if (companyPage === undefined) {
        user.getIdToken().then((idToken) => {
          getCompanies(limit, 0, idToken);
        });
      }
    }
  }, [user]);

  useEffect(() => {
    console.log("companyPage or page changed: ", companyPage, page);
    if (companyPage === undefined) {
      return;
    }

    if (companyPage.total !== totalPages) {
      setTotalPages(Math.ceil(companyPage.total / limit));
    }

    const offset = page * limit - limit;
    if (offset !== companyPage.offset) {
      if (user) {
        user.getIdToken().then((idToken) => {
          getCompanies(limit, offset, idToken);
        });
      }
    }
  }, [companyPage, page, user]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return (
    <ContentAreaWrapper>
      <LoadingOverlay loading={loadingCompanies} />
      <Typography variant="h1" sx={{ fontSize: "1.5rem" }}>
        Finns inga företag ännu
      </Typography>
      {companyPage?.data.map((company) => (
        <Typography key={company.id} variant="h1" sx={{ fontSize: "1.5rem" }}>
          {company.name}
        </Typography>
      ))}
      <Pagination
        count={totalPages}
        page={page}
        onChange={handleChangePage}
        shape="rounded"
      />
      {companiesError && (
        <Typography sx={{ color: "red" }}>{companiesError.message}</Typography>
      )}
    </ContentAreaWrapper>
  );
};

export default CompaniesScreen;
