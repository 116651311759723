import { Dispatch, createContext, useState } from "react";
import { PaginatedCompanyResponse } from "../api/companyCollection.api";

type Props = {
  children: React.ReactNode;
};

interface CompaniesContextType {
  companyPage: PaginatedCompanyResponse | undefined;
  setCompanyPage: Dispatch<
    React.SetStateAction<PaginatedCompanyResponse | undefined>
  >;
}

const defaultContext: CompaniesContextType = {
  companyPage: undefined,
  setCompanyPage: () => {},
};

export const CompaniesContext =
  createContext<CompaniesContextType>(defaultContext);

const CompaniesProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [companyPage, setCompanyPage] = useState<
    PaginatedCompanyResponse | undefined
  >(undefined);

  return (
    <CompaniesContext.Provider
      value={{
        companyPage,
        setCompanyPage,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesProvider;
